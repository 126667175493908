import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginService from '../services/LoginService';
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('../views/Login.vue'),
    // component: () => import('../views/DocumentRead.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/documents',
    component: () => import('../views/ToReadList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documents/:id',
    props: true,
    component: () => import('../views/ToReadDocument.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    props: true,
    component: () => import('../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (LoginService.isAuthenticated()) {
      next()
      return
    }
    next('/')
  }
  else if (to.fullPath == '/' && LoginService.isAuthenticated())
    next('/documents')
  next()
})

export default router
