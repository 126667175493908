import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import './styles/scss/default.scss';
import Vue2TouchEvents from 'vue2-touch-events'
import VueAxios from 'vue-axios'
import httpClient from './http/index'

Vue.config.productionTip = false
Vue.use(Vue2TouchEvents)
Vue.use(BootstrapVue)
Vue.use(VueAxios, httpClient);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
