import http from "../http";
import TokensModel from "../models/tokensModel";

const ENDPOINT = "login";
const ACCESS_TOKEN = "access_token";
const ID_TOKEN = "id_token";
const REFRESH_TOKEN = "refresh_token";

const authenticate = (email: string, password: string) =>
  http.post(ENDPOINT, { email, password, forceUpdate:true });

const logOut = (accessToken: string) => http.post("auth/logout", accessToken);

const persistTokenInSessionStorage = (tokens: TokensModel) => {
  sessionStorage.setItem(ACCESS_TOKEN, tokens.access_token);
  sessionStorage.setItem(REFRESH_TOKEN, tokens.refresh_token);
  sessionStorage.setItem(ID_TOKEN, tokens.id_token);
};

const getIDToken = () => sessionStorage.getItem(ID_TOKEN);
const getRefreshToken = () => sessionStorage.getItem(REFRESH_TOKEN);
const getAccessToken = () => sessionStorage.getItem(ACCESS_TOKEN);

const isAuthenticated = () => getIDToken() != null;

const getNewToken = (refreshToken: string) =>
  http.post(`auth/refresh_token`, refreshToken , {cache:false});

const TOKEN_HEADER = "Authorization";

export default {
  authenticate,
  persistTokenInSessionStorage,
  isAuthenticated,
  getIDToken,
  getRefreshToken,
  getNewToken,
  logOut,
  getAccessToken,
  TOKEN_HEADER,
};
