import LoginService from '@/services/LoginService';
import axios, { AxiosAdapter } from 'axios';
import Vue from 'vue';
import { cacheAdapterEnhancer} from 'axios-extensions';
import LRUCache from 'lru-cache';


const ONE_DAY = 1000 * 60 * 60 * 24;
const CAPACITY = 100;

const httpClient = axios.create({
  adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {defaultCache: new LRUCache({ maxAge: ONE_DAY, max: CAPACITY }) }),
  baseURL: process.env.VUE_APP_API_ROOT,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
});

httpClient.interceptors.request.use((config) => {
  if(config.url != 'login' && config.url != 'auth/refresh_token' )
    config.headers[LoginService.TOKEN_HEADER] = LoginService.getIDToken();
  return config;
}, (error) => {
  return Promise.reject(error);
});

httpClient.interceptors.response.use( (response) => {
  // Return a successful response back to the calling service
  return response;
  }, (error) => {

    //We want to trigger a message when there is an error related to the server
    if(error.response.status >= 500) {
      const vm = new Vue({}) 
        vm.$bvToast.toast('An error occurred, please contact your administrator', {
        title: 'Error',
        variant: 'danger',
        solid: true
      }) 
    }
    //Return all requests that are not due at the expiration of the token.   
    if (error.response.status !== 498) { 
      return Promise.reject(error);
    }
    // Try request again with new token
    const REFRESH_TOKEN = LoginService.getRefreshToken() as string;
    console.log("Here we are about to perform the refresh token")
    return LoginService.getNewToken(REFRESH_TOKEN)
      .then((tokens) => {
        console.log("here is the result of the refresh token", tokens)
        // New request with new token
        const config = error.config;
        LoginService.persistTokenInSessionStorage(tokens.data);
        config.headers['Authorization'] = LoginService.getIDToken();
        return httpClient.request(config)
      })
      .catch((error) => {
        Promise.reject(error);
      });
});

export default httpClient;