<template>
  <div id="app">
    <div v-if="!onLine" class="offline-message text-center text-white py-1">
      You are offline ...
    </div>
    <main>
      <router-view />
    </main>
  </div>
</template>
<script>
import Vue from "vue";
import "./registerServiceWorker";
export default Vue.extend({
  data: () => ({
    onLine: navigator.onLine,
  }),
  methods: {
    onchange() {
      this.onLine = navigator.onLine;
    },
  },
  mounted() {
    window.addEventListener("online", this.onchange);
    window.addEventListener("offline", this.onchange);
  },
});
</script>
<style lang="scss">
@import "./styles/scss/utils";
#app {
  background-image: url("./assets/background-login.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  .offline-message {
    background-color: $red;
  }
}
</style>
