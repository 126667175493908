/* eslint-disable no-console */
import {
  register
} from "register-service-worker";

navigator.serviceWorker.addEventListener('message', (event) => {
  if (!event.data) {
    return;
  }
  switch (event.data) {
    case 'reload-window':
      window.location.reload();
      break;
    default:
      // NOOP
      break;
  }
});

if (process.env.NODE_ENV !== "development") {
  let refreshing = false;
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (refreshing) return;
    window.location.reload();
    refreshing = true;
  });
  register(`${process.env.BASE_URL}cache_and_firebase-sw.js`, {
    ready() {
      console.log(
        "App is being served from cache by cache_and_firebase-sw" +
        "\nTHE ENVIRONMENT VARIABLE IS:" +
        process.env.NODE_ENV
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      //  new content clear cache so user gets the new version
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("I just skipped waiting");
      registration.waiting!.postMessage({
        action: "skipWaiting",
      });
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}